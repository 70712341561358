<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin" v-if="!loading">
      <offer-display  />
    </div>
    <loading v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/utilityPartials/_loading.vue';
// import TalentApply from '@/components/TalentComponents/offer/ApplyButton.vue';
// import GlobalNav from '@/components/navigation/GlobalNav.vue';
import OfferDisplay from '@/components/UniversalComponents/Offer/OfferDisplay.vue';
// import offerResult from '@/components/UniversalComponents/Search/Results/offerResults.vue';
// import jobElemList from '@/components/UniversalComponents/Offer/JobElemList.vue';

export default {
  name: 'OfferView',
  components: {
    Loading,
    // TalentApply,
    OfferDisplay,
    // GlobalNav,
    // jobElemList,
    // offerResult,

  },
  computed: {
    ...mapState('offer', {
      offerData: (state) => state.offerData,
      loading: (state) => !state.offerData,
    }),
    routeId() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      retrievedOffers: [],
      // applied: false,
      // owner: false,
      // applications: [],
    };
  },

  mounted() {
    this.getOffer();
    setTimeout(() => {
      document.querySelector('#app .inner-app .circle_bottom').style.bottom = `${document.getElementById('cont-others').getBoundingClientRect().height}px`;
    }, 1000);
  },
  watch: {
    routeId() {
      this.getOffer();
    },
  },
  beforeDestroy() {
    this.$store.dispatch('offer/clearOffer');
    document.querySelector('#app .inner-app .circle_bottom').style.bottom = '0px';
  },
  methods: {
    applyCallback() {
      this.$router.push('/');
    },
    getOffer() {
      this.$store.dispatch('offer/getOffer', this.$route.params.id);

      this.$api.post('search/offer/', { limit: 3 }).then((res) => {
        this.retrievedOffers = res.data;
      });
    },
    clickRedirect(elem) {
      window.scrollTo(0, 0);
      this.$router.push(`/offer/${elem._id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.other-offers{
  background-color: $logo_color_dark;
  text-align: left;
  h2{
    color: #FEFFFF;
    margin-bottom: 10px;
  }
  .inner-offers{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .jobElemList{
      margin-left: 2%;
      margin-right: 0;
      &:first-child{
        margin-left: 0;
      }
    }
  }
}
</style>
